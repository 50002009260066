/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'double-elimination': {
    width: 32,
    height: 33,
    viewBox: '0 0 32 33',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.714 3.745H4v7.02h8.714v-7.02zm0-1H1a1 1 0 00-1 1v7.02a1 1 0 001 1h11.714a1 1 0 001-1V7.898H15.5v16.259a.5.5 0 01-.5.5h-1.286v-2.87a1 1 0 00-1-1H1a1 1 0 00-1 1v7.02a1 1 0 001 1h11.714a1 1 0 001-1v-3.15H15a1.5 1.5 0 001.5-1.5V7.895h1.786v2.87a1 1 0 00.714.958v.042h12a1 1 0 001-1v-7.02a1 1 0 00-1-1H19v.04a1 1 0 00-.714.96v3.15h-4.572v-3.15a1 1 0 00-1-1zm18.286 1h-9v7.02h9v-7.02zM4 21.786h8.714v7.02H4v-7.02z" _fill="#898B9B"/>'
  }
})
